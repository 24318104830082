type ConsentPurposes = {
  consents: {
    [purposeId: string]: boolean;
  };
  legitimateInterests: {
    [purposeId: string]: boolean;
  };
};

export type TCData = {
  tcString: string;
  tcfPolicyVersion: 2;
  cmpId: 1000;
  cmpVersion: 1000;
  gdprApplies?: boolean;
  eventStatus: "tcloaded" | "cmpuishown" | "useractioncomplete";
  cmpStatus: "stub" | "loading" | "loaded";
  listenerId?: number;
  isServiceSpecific: boolean;
  useNonStandardStacks: boolean;
  publicerCC: string;
  purposeOneTreatment: boolean;
  outOfBand: {
    allowedVendors: {
      [vendorId: string]: boolean;
    };
    disclosedVendors: {
      [vendorId: string]: boolean;
    };
  };
  purpose: ConsentPurposes;
  vendor: ConsentPurposes;
  specialFeatureOptins: {
    [specialFeatureId: string]: boolean;
  };
  publisher: ConsentPurposes;
  customPurpose: ConsentPurposes;
  restrictions: {
    [purposeId: string]: {
      [vendorId: string]: 0 | 1 | 2;
    };
  };
};

interface TcfApi {
  (
    command: "getTCData",
    version: 2,
    callback: (tcData: TCData, success: boolean) => void,
    parameter?: number[]
  ): void;
}

interface TcfApi {
  (
    command: "addEventListener",
    version: 2,
    callback: (tcData: TCData, success: boolean) => void,
    parameter?: undefined
  ): void;
}

interface TcfApi {
  (
    command: "removeEventListener",
    version: 2,
    callback: (success: boolean) => void,
    parameter?: number
  ): void;
}

declare global {
  interface Window {
    __tcfapi: TcfApi;
  }
}

export const getCmp = () => {
  return window.__tcfapi;
};

export const loadQuantcast = () => {
  /* eslint-disable */
  var host = "theatlantic.com";
  var element = document.createElement("script");
  var url = "https://quantcast.mgr.consensu.org".concat(
    "/choice/",
    "b0K-eQJGBXxXE",
    "/",
    host,
    "/choice.js"
  );
  var uspTries = 0;
  var uspTriesLimit = 3;
  element.async = true;
  element.type = "text/javascript";
  element.src = url;

  document.body.appendChild(element);

  function makeStub() {
    var TCF_LOCATOR_NAME = "__tcfapiLocator";
    // @ts-ignore
    var queue = [];
    var win = window;
    var cmpFrame;

    function addFrame() {
      var doc = win.document;
      // @ts-ignore
      var otherCMP = !!win.frames[TCF_LOCATOR_NAME];

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement("iframe");

          iframe.style.cssText = "display:none";
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
        } else {
          setTimeout(addFrame, 5);
        }
      }
      return !otherCMP;
    }

    function tcfAPIHandler() {
      var gdprApplies;
      var args = arguments;

      if (!args.length) {
        // @ts-ignore
        return queue;
      } else if (args[0] === "setGdprApplies") {
        if (args.length > 3 && args[2] === 2 && typeof args[3] === "boolean") {
          gdprApplies = args[3];
          if (typeof args[2] === "function") {
            args[2]("set", true);
          }
        }
      } else if (args[0] === "ping") {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: "stub",
        };

        if (typeof args[2] === "function") {
          args[2](retr);
        }
      } else {
        queue.push(args);
      }
    }

    // @ts-ignore
    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === "string";
      var json = {};

      try {
        if (msgIsString) {
          json = JSON.parse(event.data);
        } else {
          json = event.data;
        }
      } catch (ignore) {}

      // @ts-ignore
      var payload = json.__tcfapiCall;

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function (retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId,
              },
            };
            if (msgIsString) {
              // @ts-ignore
              returnMsg = JSON.stringify(returnMsg);
            }
            event.source.postMessage(returnMsg, "*");
          },
          payload.parameter
        );
      }
    }

    while (win) {
      try {
        // @ts-ignore
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
        }
      } catch (ignore) {}

      if (win === window.top) {
        break;
      }
      // @ts-ignore
      win = win.parent;
    }
    if (!cmpFrame) {
      addFrame();
      win.__tcfapi = tcfAPIHandler;
      win.addEventListener("message", postMessageEventHandler, false);
    }
  }

  makeStub();
  // @ts-ignore-end
};
