declare global {
  interface Window {
    __ads: {
      pageview: Array<object>;
      pause: Array<unknown>;
    };
  }
}

export function getAdsApi() {
  // SSR version
  if (typeof window === "undefined") {
    return {
      pageview: [],
      pause: [],
    };
  }

  window.__ads = window.__ads || {};
  window.__ads.pageview = window.__ads.pageview || [];
  window.__ads.pause = window.__ads.pause || [];

  return window.__ads;
}

export function loadAdsLibrary() {
  if (typeof document === "undefined") return;

  const script = document.createElement("script");
  script.async = true;
  script.src = process.env.ADS_LIB_URL;
  document.body.appendChild(script);
}

export const defaultAdsConfig = {
  lazy_load: 2,
  datePublished: null,
  amazon: "3239",
  globals: {},
  prebid: "https://www.theatlantic.com/packages/adsjs/prebid.js",
  perfUrl: "https://data-cdn.theatlantic.com/perf.gif",
  zone: null,
  patchEventHandlers: true,
  outofpage: true,
};

/**
 * How we describe this page layout to the ad server
 */
export enum AdTemplatesEnum {
  other_unassigned = "other_unassigned",
  article_feature = "article_feature",
  article_photo = "article_photo",
  article_standard = "article_standard",
  article_video = "article_video",
  homepage = "homepage",
  landing_author = "landing_author",
  landing_channel = "landing_channel",
  landing_custom = "landing_custom", // For special projects
  landing_standard = "landing_standard",
  other_crossword = "other_crossword",
  other_notes = "other_notes",
  other_search = "other_search",
  sponsored = "sponsored",
}

/**
 * Describes a new page to ads.js and allows ads to load
 *
 * Channel and template should be chosed in accordance with the docs
 * https://atlanticmedia.atlassian.net/l/c/LU4dJGw0
 *
 * @param channel - slug of the channel
 * @param template - RevOp's name for the template
 * @param globalTargeting - key/value pairs
 * @param datePublished - For items with publication date
 * @param lazy_load - How many screens ahead should ads load by default
 */
export function registerAdsPageView({
  channel = "web",
  template,
  globalTargeting = {},
  datePublished,
  lazy_load = 2,
}: {
  channel: string;
  template: AdTemplatesEnum;
  globalTargeting: {
    [key: string]: string | string[] | undefined;
  };
  datePublished?: string;
  lazy_load?: number;
}) {
  const adsApi = getAdsApi();

  const options = {
    ...defaultAdsConfig,
    zone: `/4624/theatlantic.web/${channel}/${template}`,
    globals: globalTargeting,
    datePublished,
    lazy_load,
  };

  adsApi.pageview.push(options);
}

/**
 * Stops that ads during navigation to prevent race conditons.
 */
export function pauseAdsEffects() {
  const adsApi = getAdsApi();
  adsApi.pause.push(1);
}
