import { hasDeclinedConsent } from "utils/consent";
import { GooseArticleQuery, ArticleQuery } from "types/api";
import { useEffect } from "react";
import { AdTemplatesEnum, registerAdsPageView } from "utils/ads";
import { loadVWO } from "utils/vwo";

type InitializationEvent = {
  "gtm.start": number;
  event: "gtm.js";
};

type LandingPageViewEvent = {
  event: "pageview";
  page: {
    type: "landing";
    title: string;
  };
};

type ArticlePageViewEvent = {
  event: "pageview";
  page: {
    type: "article";
    title: string;
    article: ArticleQuery["article"] | GooseArticleQuery["article"];
    template: string;
    layout: string;
  };
};

type ErrorPageViewEvent = {
  event: "pageview";
  page: {
    type: "error";
    title: string;
    statusCode: number;
  };
};

type NewsletterSignup = {
  event: "Newsletter Signup";
  user: {
    email: string;
    newsletter: {
      id: number;
      name: string;
    };
  };
};

type NudgeScroll = {
  event: "Nudge Scroll";
  category: string;
  action: string;
  label: string;
};

type DataLayerValues =
  | InitializationEvent
  | LandingPageViewEvent
  | ArticlePageViewEvent
  | ErrorPageViewEvent
  | NewsletterSignup
  | NudgeScroll;
type DataLayer<T = unknown> = T[];

declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

/**
 * Returns an dataLayer compatible array that variables and events can be
 * pushed into.
 */
export const getDataLayer = <T = DataLayerValues>() => {
  if (typeof window === "undefined") return [];

  window.dataLayer = window.dataLayer || [];
  return window.dataLayer as DataLayer<T>;
};

/**
 * Checks for consent status and loads a different GTM depending on if a user
 * has consented or not.
 */
export const loadDataLayer = async () => {
  if (typeof document === "undefined") return;

  const declinedConsent = await hasDeclinedConsent();

  const containerId = declinedConsent
    ? process.env.GTM_CONTAINER_ID_NONCONSENTED
    : process.env.GTM_CONTAINER_ID;

  if (!declinedConsent) {
    loadVWO();
  }

  const dataLayer = getDataLayer();
  dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}`;
  document.body.appendChild(script);
};

/**
 * Track a pageview of a landing page.
 */
export const trackLandingPageView = ({
  displayName,
}: {
  displayName: string;
}) => {
  const dataLayer = getDataLayer();

  dataLayer.push({
    event: "pageview",
    page: {
      type: "landing",
      title: displayName,
    },
  });
};

/**
 * Track a pageview of an article page.
 */
export const trackArticlePageView = ({
  article,
}: {
  article: GooseArticleQuery["article"] | ArticleQuery["article"];
}) => {
  const dataLayer = getDataLayer();

  dataLayer.push({
    event: "pageview",
    page: {
      type: "article",
      title: article.title,
      article: article,
      template: "article.tsx",
      layout: "standard",
    },
  });
};

/**
 * A React hook that tracks article page views and registers them in ads.js
 */
export const useTrackPageView = ({
  article,
}: GooseArticleQuery | ArticleQuery) => {
  const categorySlugs = article.categories.map(({ slug }) => slug);
  const channelSlugs = article.channels.map(({ slug }) => slug);
  const isMagArticle = article.__typename === "MagazineArticle" ? true : false;
  const issue =
    article.__typename === "MagazineArticle" ? article.issue : undefined;

  useEffect(() => {
    // Track the article in GTM
    trackArticlePageView({ article });

    // Register the pageview in ads.js
    registerAdsPageView({
      lazy_load: 2,
      channel: article.primaryChannel?.slug || "", // primary Channel
      template: AdTemplatesEnum.article_standard,
      datePublished: article.datePublished || "",
      globalTargeting: {
        title: article.slug || "", // article slug
        grapeshot_segments: article.grapeshot?.segments || [], // brand safety list
        cat: categorySlugs, // slugs of all the categories the article is tagged with
        rubric: article.primaryChannel?.displayName || "", // primary channel name
        id: article.id.split(":")[1], // story ID
        motivation: article.editorialMotivation?.slug || "", // Editorial motivation slug
        xpost: channelSlugs, // slugs of all channels the article is tagged with
        type: isMagArticle ? "mag-reg" : "non-mag-reg", // is it a magazine article
        iss: issue?.slug || undefined, // issue slug if it's a magazine, or undefined
        issue_name: issue?.issueName || undefined, // issue name if it's a magazine, or undefined
        report: article.editorialProject?.slug || "", // Special report slug
        template: AdTemplatesEnum.article_standard, // pass template name
      },
    });
  }, [article.id]); //eslint-disable-line react-hooks/exhaustive-deps
};

export const trackErrorPageView = ({
  title,
  statusCode,
}: {
  statusCode: number;
  title: string;
}) => {
  const dataLayer = getDataLayer();

  dataLayer.push({
    event: "pageview",
    page: {
      type: "error",
      title,
      statusCode,
    },
  });
};
