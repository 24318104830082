import { loadQuantcast, TCData } from "utils/quantcast";
import { getDataLayer } from "utils/analytics";
import { parseCookies } from "nookies";

interface ConsentUpdated {
  event: "Consent Updated";
  consent: {
    consent_value: "1" | "0";
    consent_object: string;
    consent_message: "cmp default";
    consent_target: "quantcast cmp";
  };
}

/**
 * Given a CMP vendor consent object, determine if a user has declined any
 * tracking consent.
 */
export const determineConsentStatus = (tcData: TCData) => {
  // If not in a gdpr country, load the consented container
  if (!tcData.gdprApplies) return false;

  // None of this is correct.
  const consentChecks = [
    tcData.purpose.consents["1"],
    tcData.purpose.consents["2"],
    tcData.purpose.consents["3"],
    tcData.purpose.consents["4"],
    tcData.purpose.consents["5"],
    tcData.purpose.consents["6"],
    tcData.purpose.consents["7"],
    tcData.purpose.consents["8"],
    tcData.purpose.consents["9"],
    tcData.purpose.consents["10"],
  ];

  // If the user has opted out of any of the consent checks, load the
  // nonconsented container
  if (consentChecks.some((consented) => !consented)) return true;

  return false;
};

/**
 * Listen for consent changes and push consent values into the data layer
 */
export const listenForConsentChanges = () => {
  const dataLayer = getDataLayer<ConsentUpdated>();

  // this will cause a consent pixel to fire
  const pushConsent = (consentObject: string, consentValue: boolean) => {
    dataLayer.push({
      event: "Consent Updated",
      consent: {
        consent_message: "cmp default",
        consent_object: consentObject,
        consent_target: "quantcast cmp",
        consent_value: consentValue ? "1" : "0",
      },
    });
  };

  window.__tcfapi("addEventListener", 2, (tcData, success) => {
    if (success && tcData.eventStatus === "useractioncomplete") {
      pushConsent("store_information", tcData.purpose.consents["1"]);
      pushConsent("basic_ads", tcData.purpose.consents["2"]);
      pushConsent("personalized_ads_profile", tcData.purpose.consents["3"]);
      pushConsent("personalized_ads", tcData.purpose.consents["4"]);
      pushConsent("personalized_content_profile", tcData.purpose.consents["5"]);
      pushConsent("personalized_content", tcData.purpose.consents["6"]);
      pushConsent("measure_ad_performance", tcData.purpose.consents["7"]);
      pushConsent("measure_content_performance", tcData.purpose.consents["8"]);
      pushConsent("market_research", tcData.purpose.consents["9"]);
      pushConsent("improve_products", tcData.purpose.consents["10"]);

      // Remove the event listener.
      window.__tcfapi(
        "removeEventListener",
        2,
        () => {
          return undefined;
        },
        tcData.listenerId
      );
    }
  });
};

/**
 * Load Quantcast and check for consent status.
 */
export const hasDeclinedConsent = (): Promise<boolean | undefined> => {
  // If we're on the server, always resolve a noop
  if (typeof window == "undefined") return Promise.resolve(undefined);

  // If the user has a ccpa cookie, we know they've declined consent. If GDPR
  // doesn't apply, they can't decline consent.
  const { atl_ccpa, is_gdpr } = parseCookies();
  if (atl_ccpa) return Promise.resolve(true);
  if (is_gdpr !== "1" && !window.location.search.match(/debug=gdpr/))
    return Promise.resolve(false);

  loadQuantcast();
  listenForConsentChanges();

  // Promisify the vendorConsent callback and pass it into a function to
  // determine consent status.
  return new Promise((resolve) => {
    window.__tcfapi("addEventListener", 2, (tcData, success) => {
      if (success && tcData.eventStatus === "useractioncomplete") {
        resolve(tcData);

        // Remove the event listener.
        window.__tcfapi(
          "removeEventListener",
          2,
          () => {
            return undefined;
          },
          tcData.listenerId
        );
      }
    });
  }).then(determineConsentStatus);
};
